export default {
  namespaced: true,

  state: {
    roles: JSON.parse(localStorage.getItem('user.roles')) ?? {},
  },

  getters: {
    // Get all roles
    all: state => state.roles,

    /**
     * Determine if user has a specific permission
     *
     * @param {String} permission - The permission to check
     * @returns {Boolean}
     */
    can: (state) => (permission) => {
      // Check if state roles is an array
      if (Array.isArray(state.roles)) {
        return state.roles.some(role => role.permissions.includes(permission))
      }
      return false
    }
  },

  mutations: {
    /**
     * Store the roles in store and local storage.
     *
     * @param {Object} states
     * @param {Object} roles
     * @returns {Void}
     */
    store(states, roles) {
      if (roles === null) {
        return
      }

      states.roles = roles.map(role => ({
        name: role.code,
        permissions: role.permissions.map(permission => permission.name)
      }));
      localStorage.setItem('user.roles', JSON.stringify(states.roles))
    },

    /**
     * Remove the roles from store and local storage.
     *
     * @param {Object} states
     * @return {Void}
     */
    clear(states) {
      states.roles = {}
      localStorage.removeItem('user.roles')
    },
  }
}
