import formRoutes from '@/router/forms'

export default [
  {
    path: 'home',
    name: 'Public.Home',
    meta: {
      title: 'Home',
      breadcrumbTitle: 'Home',
      breadcrumb: true
    },
    component: () => import(/* webpackChunkName: "controlpanel" */ '@/views/public/Home.vue'),
    // children: homeRoutes
  },
  {
    path: '',
    name: 'Public.Forms.Overview',
    meta: {
      title: 'Home',
      breadcrumbTitle: 'Home',
      breadcrumb: true
    },
    component: () => import(/* webpackChunkName: "controlpanel" */ '@/views/public/forms/Overview.vue'),
    children: formRoutes
  },
]
