const localStorageSettings = JSON.parse(localStorage.getItem('user.settings')) ?? {}

export default {
  namespaced: true,

  state: {
    id: localStorageSettings.id !== undefined ? localStorageSettings.id : null,
    betaFeatures: localStorageSettings.betaFeatures !== undefined ? localStorageSettings.betaFeatures : false,
  },

  getters: {
    id(states) {
      return states.id
    },

    betaFeatures(states) {
      return states.betaFeatures
    }
  },

  mutations: {
    store(states, settings) {
      if (settings === null) {
        return
      }

      if (settings.id !== undefined && settings.id !== null) {
        states.id = settings.id
      }
      if (settings.beta_features !== undefined && settings.beta_features !== null) {
        states.betaFeatures = !!settings.beta_features
      }
      localStorage.setItem('user.settings', JSON.stringify(states))
    },

    clear(states) {
      states.id = null
      states.betaFeatures = false
      localStorage.removeItem('user.settings')
    },
  },

  actions: {
  },

  modules: {
  }
}
