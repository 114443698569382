import { createStore } from 'vuex'
import controlPanelModule from '@/store/controlpanel'
import snackbarModule from '@/store/snackbar'

export default createStore({
  state: {
    notifications: [],
    breadcrumbVisibility: true,
    pageMetaVisibility: true,
    isModalVisible: false,
    modalTitle: '',
    modalDescription: '',
    modalComponent: null,
    modalData: null,
    modalSubmissionData: {},
    modalSubmissionCallback: () => {},
  },

  getters: {
    notifications(state) {
      return state.notifications
    },
    modalTitle(state) {
      return state.modalTitle
    },
    modalDescription(state) {
      return state.modalDescription
    },
    modalSubmissionData(state) {
      return state.modalSubmissionData
    },
    modalComponent(state) {
      return state.modalComponent
    },
    modalData(state) {
      return state.modalData
    },
    isModalVisible(state) {
      return state.isModalVisible
    },
    breadcrumbVisibility(state) {
      return state.breadcrumbVisibility
    },
    pageMetaVisibility(state) {
      return state.pageMetaVisibility
    },
  },

  mutations: {
    addNotification(state, notification) {
      notification.isVisible = false
      state.notifications.push(notification)
    },

    deleteNotification(state, notificationIndex) {
      state.notifications.splice(notificationIndex, 1)
    },

    showNotification(state, notificationIndex) {
      state.notifications[notificationIndex].isVisible = true
    },

    hideNotification(state, notificationIndex) {
      state.notifications[notificationIndex].isVisible = false
    },

    showModal(state, params) {
      state.isModalVisible = true
      state.modalTitle = params.title ?? ''
      state.modalDescription = params.description ?? ''
      state.modalData = params.data
      state.modalComponent = params.component
      state.modalSubmissionCallback = params.onSubmit
    },

    closeModal(state) {
      state.isModalVisible = false
      state.modalData = null
      state.modalComponent = null
      state.modalTitle = ''
      state.modalDescription = ''
      state.modalSubmissionCallback = function () {}
    },

    setModalSubmissionData(state, submissionData) {
      state.modalSubmissionData = submissionData
    },

    executeModalFunctions(state) {
      state.modalSubmissionCallback(state.modalSubmissionData)
    },

    setPageMetaVisibility(state, isVisible) {
      state.pageMetaVisibility = isVisible
    },

    setBreadcrumbVisibility(state, isVisible) {
      state.breadcrumbVisibility = isVisible
    }
  },

  actions: {
    notification({ commit, getters, dispatch }, notification) {
      commit('addNotification', notification)
      var lastNotificationIndex = getters['notifications'].length - 1
      setTimeout(() => {
        commit('showNotification', lastNotificationIndex)
      }, 10)
      if (notification.timeout && notification.timeout !== null) {
        setTimeout(() => {
          dispatch('deleteNotification', 0)
        }, (15 + notification.timeout))
      }
    },

    deleteNotification({ commit }, notificationIndex) {
      commit('hideNotification', notificationIndex)
      setTimeout(() => {
        commit('deleteNotification', notificationIndex)
      }, 200)
    },

    showModal({ commit }, params) {
      commit('showModal', params)
    },

    closeModal({ commit }) {
      commit('closeModal')
      commit('setModalSubmissionData', {})
    },

    submitModal({ commit }, submissionData) {
      commit('setModalSubmissionData', submissionData)
      commit('executeModalFunctions')
    },

    renderRouteMeta({ commit }) {
      commit('setPageMetaVisibility', false)
      commit('setBreadcrumbVisibility', false)
      setTimeout(() => {
        commit('setPageMetaVisibility', true)
        commit('setBreadcrumbVisibility', true)
      }, 10)
    }
  },

  modules: {
    'controlpanel': controlPanelModule,
    'snackbar': snackbarModule
  }
})
