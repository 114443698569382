import fieldServiceModule from '@/store/controlpanel/tempo/fieldService'

export default {
  namespaced: true,

  state: {
    attributes: {
      startTime: 1,
      endTime: 2,
      hoursWorked: 3,
      licenseplate: 4,
      description: 5,
      asbestos: 6,
      xrf: 7,
      dateUid: 1,
      scanned: 8
    },
    articles: {
      FieldService: 1,
    }
  },

  getters: {
    startTime(states){
      return states.attributes.startTime
    },
    endTime(states){
      return states.attributes.endTime
    },
    hoursWorked(states){
      return states.attributes.hoursWorked
    },
    licenseplate(states){
      return states.attributes.licenseplate
    },
    description(states){
      return states.attributes.description
    },
    asbestos(states){
      return states.attributes.asbestos
    },
    xrf(states){
      return states.attributes.xrf
    },
    article_FieldService(states){
      return states.articles.FieldService
    },
    attributeDateUid(states){
      return states.attributes.dateUid
    },
    scanned(states){
      return states.attributes.scanned
    },
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    'fieldService': fieldServiceModule
  }
}
