export default {
  namespaced: true,

  state: () => ({
    activeDate: (new Date).toISOString().split('T')[0],
    activeID: 0,
  }),

  getters: {
    getActiveDate(state) {
      return state.activeDate
    },

    getActiveID(state) {
      return state.activeID
    }
  },

  actions: {
    setActiveDate({commit}, newDate) {
      commit('setActiveDate', newDate)
    },

    setActiveID({commit}, newID) {
      commit('setActiveDate', newID)
    }
  },

  mutations: {
    setActiveDate(state, date) {
      state.activeDate = date
    },

    setActiveID(state, id) {
      state.activeDate = id
    },
  },
}
