import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

const app = createApp(App)
app.provide('axios', axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true
}))
app.provide('msal', new PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.VUE_APP_MSAL_AUTHORITY}`,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT,
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_REDIRECT
  },
  cache: {
    cacheLocation: 'localStorage',
  }
}))

app.use(store).use(router)

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
    onError: function (event) {
      const user = localStorage.getItem('user')
      if (user && user !== undefined && user !== null && String(user).length) {
        const userDetails = JSON.parse(user)
        event.setUser(
          userDetails.id,
          userDetails.email,
          userDetails.name
        )
      }
    }
  })
  app.use(Bugsnag.getPlugin('vue'))
}

app.mount('#app')
