import tempoModule from '@/store/controlpanel/tempo'
import userModule from '@/store/controlpanel/user'

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    'tempo': tempoModule,
    'user': userModule
  }
}
