<template>
  <router-view/>
</template>

<script>
export default {
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },


  created() {
    const loginpath = window.location.pathname
    if (loginpath !== '/auth/inloggen' && loginpath !== '/') {
      sessionStorage.setItem('redirectUrl', String(loginpath));
    }

    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },

  methods: {
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
      this.$store.dispatch('snackbar/show', {
        type: 'info',
        title: 'Een update is beschikbaar',
        confirmText: 'Herladen',
        onConfirm: () => this.refreshApp()
      })
    },

    refreshApp() {
      this.updateExists = false
      if (!this.registration || !this.registration.waiting) return
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  },
}
</script>
