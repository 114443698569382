import settingsModule from '@/store/controlpanel/user/settings'
import roleModule from '@/store/controlpanel/user/authorization'

export default {
  namespaced: true,

  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    'settings': settingsModule,
    'role': roleModule
  }
}
