export default [
  {
    path: ':section',
    name: 'Public.Forms.Single.Section',
    meta: {
      title: 'Formulier sectie',
      breadcrumbTitle: 'Formulier sectie',
      breadcrumb: true
    },
    component: () => import(/* webpackChunkName: "Public" */ '@/views/public/forms/form/Section.vue'),
  },
  {
    path: 'controleren',
    name: 'Public.Forms.Single.Finalize',
    meta: {
      title: 'Formulier controleren',
      breadcrumbTitle: 'Controleren',
      breadcrumb: true
    },
    component: () => import(/* webpackChunkName: "Public" */ '@/views/public/forms/form/Finalize.vue'),
  },
  {
    path: 'bekijken',
    name: 'Public.Forms.Single.View',
    meta: {
      title: 'Formulier bekijken',
      breadcrumbTitle: 'Bekijken',
      breadcrumb: true
    },
    component: () => import(/* webpackChunkName: "Public" */ '@/views/public/forms/form/View.vue'),
  },
]
