import singleRoutes from '@/router/public/forms'

export default [
  {
    path: ':name',
    name: 'Public.Forms.Single',
    meta: {
      title: 'Formulier',
      breadcrumbTitle: 'Formulier',
      breadcrumb: true
    },
    component: () => import(/* webpackChunkName: "controlpanel" */ '@/views/public/forms/Single.vue'),
    children: singleRoutes
  },
]
