export default {
  namespaced: true,

  state: {
    title: '',
    type: 'info',
    availableTypes: ['success', 'info', 'warning', 'error'],
    confirmText: 'OK',
    onConfirm: () => {},
    visible: false
  },

  getters: {
    isVisible(state) {
      return state.visible
    },

    hasTitle(state) {
      return state.title && state.title.length ? true : false
    },

    title(state) {
      return state.title
    },

    type(state) {
      return state.type
    },

    confirmText(state) {
      return state.confirmText
    },

    onConfirm(state) {
      return state.onConfirm
    },
  },

  mutations: {
    show(state, params) {
      if (!params.title || !params.title.length) return
      if (!params.onConfirm || typeof params.onConfirm !== 'function') return

      state.title = params.title
      state.type = params.type && params.type.length && state.availableTypes.includes(params.type) ? params.type : 'info'
      state.confirmText = params.confirmText && params.confirmText.length ? params.confirmText : 'OK'
      state.onConfirm = params.onConfirm
      state.visible = true
    },

    confirm(state) {
      state.onConfirm()
    },

    close(state) {
      state.visible = false

      setTimeout(() => {
        state.title = ''
        state.type = 'info'
        state.confirmText = 'OK'
        state.onConfirm = () => {}
      }, 200)
    },
  },

  actions: {
    show({ commit }, params) {
      commit('show', params)
    },

    confirm({ commit }) {
      commit('confirm')
      commit('close')
    }
  },

  modules: {
  }
}
