import { createRouter, createWebHistory } from 'vue-router'
import publicRoutes from '@/router/public'

const routes = [
  {
    path: '/',
    name: 'Public',
    component: () => import(/* webpackChunkName: "public-synergy" */ '@/views/Public.vue'),
    children: publicRoutes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.params.scrollBehavior === 'skip') return
    if (!savedPosition) {
      return { left: 0, top: 0 }
    }
    return savedPosition
  }
})

export default router
